$primary: #4067BD;
$secondary: #F08D3A;
$dark: #15223D;

@import "~bootstrap/scss/bootstrap";

*{
	box-sizing: border-box;
}

html, body{
	padding: 0;
	margin: 0;
}

#page-content{
	header{
		position: fixed;
		z-index: 100;
	}

	#welcome{
		height: 100vh;
		background: url(assets/map.jpg) no-repeat center center fixed;
		background-size: cover;
		background-blend-mode: overlay;
	}

	#scroll-button-container{
		position: absolute;
		bottom: 30px;
		left: 50vw;
		transform: translateX(-50%);

		#scroll-button{
			border-radius: 50% 50%;
			width: 3.2em;
			height: 3.2em;
			text-align: center;

			svg{
				display: inline-block;
			}
		}
	}

	#complaints-link{
		margin-top: 0.5rem;

		a{
			color: white;
			text-decoration: underline;
		}
	}

	#complaints{
		#complaints-title{
			padding-top: 100px;
		}
	}
}